import APIService from '../../../../services/api';
import { trackEvent } from '@vpp-frontend-components/common';
import getContactInfoTarget from '../../../utils/get-contact-info-target';
import redirectReauthUrl from '../../../utils/redirect-reauth-url';
import {
  FETCH_COMPONENT,
  FETCH_CONTACT_INFO_ERROR,
  FETCH_SELLER_INFO_PHONES,
  REMOVE_RECAPTCHA,
  RENDER_FRAUD_MODAL,
  SHOW_FRAUD_SNACKBAR,
  SHOW_RECAPTCHA,
  UPDATE_TOKEN_RESPONSE,
} from '../../../vip/vis/types';
import { onLoadRecaptchav3Token } from '../../../../components/recaptcha';

const dispatchContactInfoErrors = (dispatch, component) => {
  dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
  dispatch({ type: FETCH_CONTACT_INFO_ERROR, component });
};

const dispatchContactInfo = (
  { component, itemId, contactType, recaptchav2token, ajaxBackendParams, action, recaptchaActionType, params },
  dispatch,
) => recaptchaTokenv3 =>
  APIService.getSellerContactInfo(
    itemId,
    contactType,
    recaptchaTokenv3,
    recaptchav2token,
    ajaxBackendParams,
    action,
    params,
  )
    .then(payload => {
      dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
      // eslint-disable-next-line promise/always-return
      if (payload.recaptcha) {
        trackEvent(payload.recaptcha.track);
        dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component, recaptchaActionType } });
      } else if (payload.display_message) {
        dispatch({ type: SHOW_FRAUD_SNACKBAR, payload: { ...payload, component } });
      } else if (payload.fraud_modal) {
        dispatch({ type: RENDER_FRAUD_MODAL, modal: payload.fraud_modal });
      } else if (contactType === 'show-phones') {
        trackEvent(payload.track);
        dispatch({ type: FETCH_SELLER_INFO_PHONES, payload });
      } else {
        trackEvent(payload.track);
        const target = getContactInfoTarget(payload);
        if (target) {
          dispatch({ type: UPDATE_TOKEN_RESPONSE, payload: { component, token: null } });
          window.location.href = target;
        } else {
          dispatch({ type: FETCH_CONTACT_INFO_ERROR, component });
        }
      }
    })
    .catch(e => {
      if (redirectReauthUrl(e)) {
        return;
      }

      dispatchContactInfoErrors(dispatch, component);
    });

const execFetchContactInfo = (dispatch, args) => {
  const {
    component,
    itemId,
    contactType,
    action,
    recaptchav2token,
    ajaxBackendParams,
    recaptchaActionType,
    recaptchaSiteKey,
    params,
    vertical,
  } = args;
  params.vertical = vertical;
  dispatch({ type: FETCH_COMPONENT, isFetchingData: true, component });
  if (recaptchav2token) {
    dispatch({ type: REMOVE_RECAPTCHA, payload: { component } });
    dispatchContactInfo(
      { component, itemId, contactType, recaptchav2token, ajaxBackendParams, action, recaptchaActionType, params },
      dispatch,
    )();
  } else {
    onLoadRecaptchav3Token(
      recaptchaSiteKey,
      action,
      dispatchContactInfo(
        { component, itemId, contactType, ajaxBackendParams, action, recaptchaActionType, params },
        dispatch,
      ),
      () => dispatchContactInfoErrors(dispatch, component),
    );
  }
};

export { dispatchContactInfoErrors, dispatchContactInfo, execFetchContactInfo };
