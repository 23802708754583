import { trackEvent } from '@vpp-frontend-components/common';
import APIService from '../../../../services/api';
import {
  CREATE_BUDGET_ERROR,
  CREATE_BUDGET_START,
  FETCH_BUDGET_COMPONENTS,
  FETCH_ERROR,
  FETCH_START,
} from '../../../vip/vis/types';

const fetchBudgetComponents = ({ id, app, message }, dispatch) => {
  APIService.getProduct(id, {
    components_ids: 'main_actions,new_budget_message,budget_sent_message,budget_demand',
    app,
    device: 'desktop',
  })
    .then(payload => {
      payload.service_flash_message = message;
      dispatch({ type: FETCH_BUDGET_COMPONENTS, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const execUpdateBudgetComponents = (dispatch, args) => {
  const { id, app, message } = args;
  dispatch({ type: FETCH_START, id });
  fetchBudgetComponents({ id, app, message }, dispatch);
};

const execOnCreateBudgetDemmand = (dispatch, args) => {
  const { id, app, itemId, text, track } = args;
  dispatch({ type: CREATE_BUDGET_START, itemId });
  APIService.createBudgetDemand(itemId, text, { app })
    .then(payload => {
      if (payload.success) {
        trackEvent(track);
      }

      fetchBudgetComponents(
        {
          id,
          app,
          message: { message: payload.display_message.text, show: true, type: payload.success ? 'success' : 'error' },
        },
        dispatch,
      );
    })
    .catch(e => {
      dispatch({ type: CREATE_BUDGET_ERROR, error: e });
    });
};

export { fetchBudgetComponents, execUpdateBudgetComponents, execOnCreateBudgetDemmand };
