const getContactInfoTarget = payload => {
  let hrefTarget = null;
  if (payload.whatsapp) {
    hrefTarget = payload.whatsapp.target;
  } else if (payload.phones) {
    const value = payload.phones.find(phoneValue => phoneValue);
    hrefTarget = value.target;
  }
  return hrefTarget;
};

export default getContactInfoTarget;
