const getFetchContactInfo = (
  component,
  itemId,
  contactType,
  action,
  recaptchav2token,
  ajaxBackendParams,
  recaptchaActionType,
  recaptchaSiteKey,
  params,
  getState,
) => {
  const { vertical } = getState();
  return {
    component,
    itemId,
    contactType,
    action,
    recaptchav2token,
    ajaxBackendParams,
    recaptchaActionType,
    recaptchaSiteKey,
    params,
    vertical,
  };
};

export { getFetchContactInfo };
