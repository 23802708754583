const getOnCreateBudgetDemmand = (itemId, text, track, getState) => {
  const { id, app } = getState();
  return { id, app, itemId, text, track };
};

const getUpdateBudgetComponents = (message, getState) => {
  const { id, app } = getState();
  return { id, app, message };
};

export { getOnCreateBudgetDemmand, getUpdateBudgetComponents };
